<template>
	<div class="row">
		<div class="col-12 col-lg-7 pe-md-5">
			<h3>&nbsp;</h3>
			<h3>Rotary T&T Moves for Childhood Obesity Prevention</h3>
			<p>
				<i class="bi bi-calendar me-1"></i> April 24 2022, 7:00 a.m.<br/>
				<i class="bi bi-pin-map me-1"></i> Queen's Park Savannah, Port of Spain<br/>
				<i class="bi bi-person-lines-fill me-1"></i> Maria Mohammed-Maharaj (E:maria@kamodesigns.com | M: 789-6000)
			</p>
		</div>
		<div class="col-12 col-lg-5 border">
			<img class="img-fluid" src="../assets/cop_banner.png"/>
		</div>
	</div>
	<div class="row mt-5 border-top sticky-top bg-white" style="box-shadow: 0 20px 5px 5px rgba(0,0,0,0)">
		<div class="col-auto col-lg-2 p-3 ps-0 text-dark border-bottom border-5 border-dark">
			<h5><i class="bi bi-ticket-detailed me-1"></i> Tickets</h5>
		</div>
		<div class="col p-3 pe-0 border-bottom border-1 border-light" align="right">
			<button id="btnOpenCartModal" 
			class="btn btn-dark col-12 col-lg-4" 
			data-bs-toggle="modal" 
			data-bs-target="#submitInfoModal" 
			@click="btnShoppingCartClick"
			:disabled="cartTotal < 1"
			v-show="isLoggedIn"
			>
				<i class="bi bi-bag me-1"></i> {{ cartTotal?.toLocaleString('en-UK', { style: 'currency', currency: 'TTD' }) }}
			</button>
			<button class="btn btn-dark col-12 col-lg-4" 
			data-bs-toggle="modal" 
			data-bs-target="#loginSignupModal"
			:disabled="cartTotal < 1"
			@click="btnShoppingCartClick"
			v-show="!isLoggedIn"
			>
				<i class="bi bi-bag me-1"></i> {{ cartTotal?.toLocaleString('en-UK', { style: 'currency', currency: 'TTD' }) }}
			</button>
		</div>
	</div>
	<div class="row bg-light">
		<div class="col-12 my-5">
			<ul class="list-group mb-3">
				<li class="list-group-item">
					<div class="row my-3">
						<div class="col">
							<h4>General Admission - Adult</h4>
							<p>Includes parking at Queen's Hall, a brunch box and light refreshments.</p>
						</div>
						<div class="col-12 col-md-auto mb-3">
							<span>
								<strong class="fs-4">$165 TTD </strong><br/>
								<small>Incl. of fees <i class="bi bi-info-circle-fill"></i></small>
							</span>
						</div>
						<div class="col-12 col-md-auto">
							<label class="small">No. of tickets:</label>
							<select class="form-select"
							v-model="shoppingCart[0]"
							@change="updateCart(shoppingCart[0])">
								<option disabled selected>Choose</option>
								<option v-for="index in maxPurchase+1" 
								:key="index" 
								:value="{id: 1, qty: index-1, ticket:'General Admission - Adult', price: (index-1) * 165}"
								>
									{{index-1}} Ticket<span v-if="index-1 >1 || index-1 === 0">s</span>
								</option>
							</select>
						</div>
					</div>
				</li>
			</ul>
			<ul class="list-group mb-3">
				<li class="list-group-item">
					<div class="row my-3">
						<div class="col">
							<h4>General Admission - Teen</h4>
							<p>For persons between the ages of 12 and 16 years. 
							Includes a brunch box and light refreshments.</p>
						</div>
						<div class="col-12 col-lg-auto mb-3">
							<span>
								<strong class="fs-4">$70 TTD </strong><br/>
								<small>Incl. of fees <i class="bi bi-info-circle-fill"></i></small>
							</span>
						</div>
						<div class="col-12 col-lg-auto">
							<label class="small">No. of tickets:</label>
							<select class="form-select"
							v-model="shoppingCart[1]"
							@change="updateCart(shoppingCart[1])">
								<option disabled selected>Choose</option>
								<option v-for="index in maxPurchase+1" 
								:key="index" 
								:value="{id: 2, qty: index-1, ticket:'General Admission - Teen', price: (index-1) * 70}"
								>
									{{index-1}} Ticket<span v-if="index-1 >1 || index-1 === 0">s</span>
								</option>
							</select>
						</div>
					</div>
				</li>
			</ul>
			<ul class="list-group mb-3">
				<li class="list-group-item">
					<div class="row my-3">
						<div class="col">
							<h4>General Admission - Child</h4>
							<p>
								For persons age 12 and under. <strong>Vaccination card not required</strong>. Includes a brunch box and light refreshments.
							</p>
						</div>
						<div class="col-12 col-lg-auto mb-3">
							<span>
								<strong class="fs-4">$FREE </strong><br/>
								<small>Incl. of fees <i class="bi bi-info-circle-fill"></i></small>
							</span>
						</div>
						<div class="col-12 col-lg-auto">
							<label class="small">No. of tickets:</label>
							<select class="form-select"
							v-model="shoppingCart[2]"
							@change="updateCart(shoppingCart[2])">
								<option disabled selected>Choose</option>
								<option v-for="index in maxPurchase+1" 
								:key="index" 
								:value="{id: 3, qty: index-1, ticket:'General Admission - Child', price: (index-1) * 0}"
								>
									{{index-1}} Ticket<span v-if="index-1 >1 || index-1 === 0">s</span>
								</option>
							</select>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="row p-3 pt-5">
		<div class="col-12 col-lg-4">
			<h3>Rotary T&T Moves for Childhood Obesity Prevention</h3>
		</div>
		<div class="col-12 col-lg-8">
			<h4>Event Details</h4>
			<ul>
				<li>All participants 12 years old and over should walk with their individual vaccination cards (hard or soft copies accepted)</li>
				<li>All participants should walk with their individual or group registration confirmations (hard or soft copies accepted)</li>
				<li>Participants are advised that while this event is designed to be a low-impact activity, persons with any pre-existing medical conditions should take the necessary precautions before attending and anyone who is uncertain about their current health or physical capabilities should consult with a physician before participating.</li>
				<li>Persons should appropriately and adequately prepare themselves and/or participating children for the activity and are advised to take any and all precautions for the day (e.g. medication, sun-screen, umbrellas, ponchos, etc.)</li>
				<li>Persons with physical disabilities are encouraged to participate and shall be accommodated; however, please ensure all necessary personal precautions are taken in advance</li>
				<li>The starting point of the Rotary T&amp;T Moves for COP Walk/Run event is Queen's Hall, Port of Spain</li>
				<li>There is adequate parking within Queen's Hall itself and gates will be open from 6:30 a.m.</li>
				<li>All participants will be screened and checked-in and groups may depart Queen's Hall and begin their walk/run anytime from 7:00 a.m.. Please note that for safety reasons, groups of up to 10 persons will be allowed to begin their walk/run every 3 to 5 minutes.</li>
				<li>Traffic wardens shall be present to assist with crossing the road from Queen's Hall to the Queen's Park Savannah and back for the duration of the event.</li>
				<li>Persons will be provided with bottled water when leaving the venue and there will also be a designated water station at the Grand Stand, Queen's Park Savannah.</li>
				<li>Persons have the option to walk/run once or twice or more around the Savannah and then back to Queen's Hall</li>
				<li>Emergency Services shall be present and provided should the need arise</li>
				<li>We estimate most persons will finish their walk/run and be back to Queen's Hall between 8:30 a.m. and 10:30 a.m.</li>
				<li>Upon returning to Queen's Hall, a packaged meal will be distributed and persons have the option to take-away or dine-in at the Garden Theatre which is a covered outdoor space. Please note that this area is a designated safe zone and all health and safety protocols will be observed and enforced.</li>
				<li>Your contribution (TT$165 for adults, TT$70 for 12-16 year olds) covers costs including: the venue and decor, water and meals, traffic wardens and parking (***additional beverages will be on sale at the venue)</li>
				<li>Participants are asked to take pictures and videos on the day and post/share to social media using the following hashtags:<br>#District7030Moves<br>#WorldObesityDay<br>#ThisIsHowIMove<br>#EverybodyNeedsToAct</li>
				</ul>
			<!-- <p>
				Alongside the new District 7030 World Obesity Day Challenge, members and clubs are also asked to help raise awareness of the District's ongoing COP efforts. To do this, everyone is invited to join us as we move for COP. 
			</p>
			<p>
				The aim is to have the starting point of the Walk at Queen's Hall from 7:00 am.  There is adequate parking at Queen’s Hall.

Traffic wardens to assist with crossing the road from Queen's Hall to the Queen's Park Savannah as we will be leaving in groups of 10 every 3 to 5 minutes.  Persons have the option to walk once or twice or more around the Savannah and then back to Queen's Hall, with the assistance of the Traffic Wardens, which we estimate most will be back between 8:30 to 10:30 am.
			</p>
			<p>
Once back to the starting point, packaged brunch meals will be distributed and persons have the option to take away or dine at the Garden Theatre which is a covered open area.  Note:  this area space is a safe zone. 
			</p>
			<p>
Queen's Hall will also activate the bar area at the Garden Theatre for those who want to Fellowship after the Walk.
			</p>
			<p>
For the contribution we are looking at $165.00, this would include the cost of venue, brunch and traffic wardens.  Kids under 12 years are free and teens $70.00
			</p>
			<p>
Any surplus realised will be donated to The Rotary Foundation divided amongst the Clubs participating.
			</p>
			<p>
Rotarians, Rotaractors, Interactors, EarlyActors, families, Friends of Rotary and members of the public can participate.
			</p> -->
		</div>
	</div>
	<!-- Modal -->
	<div class="modal fade" id="submitInfoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="submitInfoModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg modal-dialog-scrollable">
			<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="staticBackdropLabel">Rotary T&T Moves for Childhood Obesity Prevention. </h5>
				<button id="btnCloseCartModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<ShoppingCart 
				:shoppingCart="tempCart" 
				:stripePaymentAmount="stripePaymentAmount" 
				:cartTotal="cartTotal" 
				:key="resetPaymentForm"
				@closeCartModal="closeCartModal" />
			</div>
			</div>
		</div>
	</div>
	<!-- Modal -->
	<div class="modal fade" id="loginSignupModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="loginSignupModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-md modal-dialog-scrollable">
			<div class="modal-content">
			<div class="modal-header border-0">
				<button id="btnCloseLoginModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<component :is="activeAuthComponent" @setAuthComponent="setAuthComponent" @closeLoginModal="closeLoginModal" />
			</div>
			</div>
		</div>
	</div>
	<!-- Modal -->
	<a id="btnOpenOrderCompleteModal" data-bs-toggle="modal" data-bs-target="#orderCompleteModal"></a>
	<div class="modal fade" id="orderCompleteModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="loginSignupModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-lg modal-dialog-scrollable">
			<div class="modal-content">
			<div class="modal-header border-0">
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body p-5" align="center">
				<h1 class="xx-larger fw-bold text-primary">THANK YOU FOR YOUR CONTRIBUTION!</h1>
				<h3 class="xx-larger text-secondary my-4">See you on March 06th at Queen's Hall.</h3>
				<img class="img-fluid" src="../assets/cop_banner.png"/>
			</div>
			</div>
		</div>
	</div>
</template>
<script>
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm.vue';
import LoginForm from '../components/auth/LoginForm.vue';
import SignupForm from '../components/auth/SignupForm.vue';
import Cart from './Cart.vue';

const stripeFees = {
	MDR: 1,
	TRXN_FEE: 0,
};

export default {
	components: { ShoppingCart: Cart, LoginForm, SignupForm, ForgotPasswordForm },
	name: 'Event',
	computed:{
		cartTotal(){
			const {shoppingCart} = this;
			let cartTotal = 0;
			shoppingCart.forEach((item) => {
				if(Object.keys(item).length > 0){
					parseFloat(cartTotal += item.price);
				}
			});
			return cartTotal;
		},
		userProfile(){
			return this.$store.state.userProfile;
		},
		isLoggedIn(){
			return this.$store.state.isLoggedIn;
		}
	},
	data() {
		return {
			stripePaymentAmount: 0,
			shoppingCart:[],
			tempCart:[],
			maxPurchase: 3,
			resetPaymentForm: false,
			activeAuthComponent: 'LoginForm',
		}
	},
	methods:{
		updateCart(cartItem){
			this.resetPaymentForm = false;
			const {tempCart, cartTotal} = this;
			const findIndex = tempCart.findIndex((item) => item.id === cartItem.id);
			if(findIndex > -1){
				this.tempCart.splice(findIndex, 1, cartItem);
			}else{
				this.tempCart.push(cartItem);
			}

			//get Stripe amount
			const stripePaymentAmount = Math.trunc(((cartTotal + stripeFees.TRXN_FEE)/stripeFees.MDR).toFixed(2) *100);
			this.stripePaymentAmount = stripePaymentAmount;
			this.$store.dispatch("updateStripePaymentIntent", { stripePaymentAmount : stripePaymentAmount });
		},
		btnShoppingCartClick(){
			this.resetPaymentForm = true;
			//console.log(this.shoppingCart);
		},
		closeLoginModal(){
			document.getElementById('btnCloseLoginModal').click();
			this.$nextTick();
			setTimeout(()=> { 
				document.getElementById('btnOpenCartModal').click();
			}, 1000)
			
		},
		closeCartModal(){
			document.getElementById('btnCloseCartModal').click();
			this.shoppingCart = [];
			this.$nextTick();
			setTimeout(()=> { 
				document.getElementById('btnOpenOrderCompleteModal').click();
			}, 1000)
			
		},
		setAuthComponent(component){
			this.activeAuthComponent = component;
		},
	},
	mounted(){
		document.getElementById('btnOpenCartModal').click();
	},
}
</script>