<template>
	<div class="mb-5" id="pageTop" >
		<div class="row">
            <div class="col-12 ps-5 pe-5">
				<div class="row">
					<div class="alert alert-danger col-12" align="center" role="alert" v-if="loginError">
						Invalid email address or password!
					</div>
					<div class="col-12">
						<h1>Login</h1>
						<p>Please login to continue.</p>
					</div>
					<div class="col-12 my-2">
						<label>Email address</label>
						<input type="email" class="form-control" placeholder="" v-model.trim="login.email"/>
					</div>
					<div class="col-12 my-2">
						<label>Password</label>
						<div class="input-group">
							<input :type="showPassword ? 'text' : 'password'" class="form-control passwordbox" placeholder="" v-model.trim="login.password" />
							<button class="btn btn-outline-secondary passwordbox" type="button" v-if="!showPassword" @click="showPassword = !showPassword">
								<i class="bi bi-eye"></i>
							</button>
							<button class="btn btn-outline-secondary passwordbox" type="button" v-if="showPassword" @click="showPassword = !showPassword">
								<i class="bi bi-eye-slash"></i>
							</button>
						</div>
						<p align="right">
							<button class="btn btn-link btn-sm text-reset"  @click="$emit('setAuthComponent', 'ForgotPasswordForm')">
								Forgot password
							</button>
						</p>
					</div>
					<div class="col-12 my-1 d-grid">
						<button class="btn btn-dark btn-lg" :disabled="!isFormValid || isBusy" @click="btnLoginClick">
							<span v-show="!isBusy">Log in</span>
							<div class="spinner-border text-light" role="status" v-show="isBusy">
								<span class="sr-only"></span>
							</div>
						</button>
					</div>
					<div class="col-12 my-3">
						<button class="btn btn-link text-reset" @click="$emit('setAuthComponent', 'SignupForm')">
							Not registered yet? Create an account.
						</button>
					</div>
				</div>
            </div>
		</div>
	</div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
export default {
	name: "login-form",
	components: {
		
	},
	computed:{
		isFormValid(){
			if(!this.login.email) return false
			if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.login.email)) return false
			if(!this.login.password) return false
			return true
		}
	},
	data(){
		return{
			showPassword: false,
			loginError:false,
			isBusy: false,
			login:{
				email: "",
				password: "",
			},
		}
	},
	methods:{
		btnLoginClick(){
			this.isBusy = true
			this.loginError = false
			const auth = getAuth();
			signInWithEmailAndPassword(auth, this.login.email, this.login.password)
			.then((userCredential) => {
				let user = userCredential.user
				this.$store.dispatch("fetchProfile", user)
				this.isBusy = false
				if(typeof this.$route.query.from !== "undefined"){
					let decoded_uri = decodeURIComponent(this.$route.query.from)
					this.$router.push(decoded_uri)
				}else{
					this.$emit('closeLoginModal');
					//window.location.reload();
				}
			})
			.catch((error) => {
				console.log(error.code, error.message)
				this.isBusy = false
				this.loginError = true
				window.scrollTo(0,0);
			});
		}
	},

	mounted(){
		this.$store.commit("setPageTitle", "login");
	}

}
</script>
