export const clubs = {
	ttclubs: [ 
		{ club_name: "RC Arima" },
		{ club_name: "RC Central Port of Spain" }, 
		{ club_name: "RC Chaguanas" }, 
		{ club_name: "RC Diego Martin" }, 
		{ club_name: "RC Maracas-St. Joseph" }, 
		{ club_name: "RC Maraval" }, 
		{ club_name: "RC Penal" }, 
		{ club_name: "RC Piarco" }, 
		{ club_name: "RC Point Fortin" }, 
		{ club_name: "RC Pointe-A-Pierre" }, 
		{ club_name: "RC Port of Spain" }, 
		{ club_name: "RC Port of Spain West" }, 
		{ club_name: "RC Princes Town" }, 
		{ club_name: "RC San Fernando" }, 
		{ club_name: "RC San Fernando-South" }, 
		{ club_name: "RC San Juan" }, 
		{ club_name: "RC Sangre Grande" }, 
		{ club_name: "RC Southwest-Tobago" }, 
		{ club_name: "RC St. Augustine" }, 
		{ club_name: "RC St. Augustine West" },
		{ club_name: "RC St. Kitts" }, 
		{ club_name: "RC St. Lucia" }, 
		{ club_name: "RC St. Vincent" }, 
		{ club_name: "RC St. Vincent South" }, 
		{ club_name: "RC Tobago" }, 
		{ club_name: "RTC Arima" }, 
		{ club_name: "RTC Arima West" }, 
		{ club_name: "RTC Central Port of Spain" }, 
		{ club_name: "RTC Chaguanas" }, 
		{ club_name: "RTC Mount Hope" }, 
		{ club_name: "RTC Penal" }, 
		{ club_name: "RTC Piarco" }, 
		{ club_name: "RTC Piarco (U.W.I)" }, 
		{ club_name: "RTC Point Fortin" }, 
		{ club_name: "RTC Port Of Spain" }, 
		{ club_name: "RTC Port of Spain West" }, 
		{ club_name: "RTC San Fernando" }, 
		{ club_name: "RTC San Fernando" }, 
		{ club_name: "RTC San Fernando South" }, 
		{ club_name: "RTC Sangre Grande" }, 
		{ club_name: "RTC Southwest Tobago" }, 
		{ club_name: "RTC St. Augustine" }, 
		{ club_name: "RTC St. Augustine West" }, 
	]
};