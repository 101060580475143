<template>
	<div class="row">
		<div class="col-12 col-lg-3">
		</div>
		<div class="col-12 ps-4 pe-4">
			<div class="row">
				<div class="col">
					<h1>Signup</h1>
					<p>Create your account to continue.</p>
				</div>
				<div class="col-auto">
					<span @click="$emit('setAuthComponent', 'LoginForm')" class="pointer m-3 mt-1">
						<i class="bi bi-chevron-left "></i> Go back
					</span>
				</div>
				
				<div class="col-12 my-2">
					<label class="small text-muted">First name*</label>
					<input type="text" class="form-control"  v-model="signup.fname" />
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Last name*</label>
					<input type="text" class="form-control"  v-model="signup.lname" />
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Email address*</label>
					<input type="email" class="form-control"  v-model="contact.email" />
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Phone*</label>
					<input type="number" class="form-control"  v-model="contact.phone" />
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Gender*</label>
					<select class="form-select"  v-model="signup.gender">
						<option value="" selected disabled></option>
						<option>Female</option>
						<option>Male</option>
						<option>Non-Binary</option>
					</select>
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Country*</label>
					<select class="form-select"  v-model="signup.country">
						<option value="" selected disabled></option>
						<option value="Trinidad & Tobago">Trinidad & Tobago</option>
						<option disabled>------</option>
						<option>Other</option>
					</select>
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Password*</label>
					<div class="input-group">
						<input :type="showPassword ? 'text' : 'password'" class="form-control passwordbox" v-model="password" />
						<button class="btn btn-outline-secondary passwordbox" type="button" v-if="!showPassword" @click="showPassword = !showPassword">
							<i class="bi bi-eye"></i>
						</button>
						<button class="btn btn-outline-secondary passwordbox" type="button" v-if="showPassword" @click="showPassword = !showPassword">
							<i class="bi bi-eye-slash"></i>
						</button>
					</div>
				</div>
				<div class="col-12 my-2">
					<label class="small text-muted">Confirm password*</label>
					<div class="input-group">
						<input :type="showPassword ? 'text' : 'password'" class="form-control passwordbox"  v-model="confirm_password" />
						<button class="btn btn-outline-secondary passwordbox" type="button" v-if="!showPassword" @click="showPassword = !showPassword">
							<i class="bi bi-eye"></i>
						</button>
						<button class="btn btn-outline-secondary passwordbox" type="button" v-if="showPassword" @click="showPassword = !showPassword">
							<i class="bi bi-eye-slash"></i>
						</button>
					</div>
				</div>
				<div class="col-12 my-3">
					<div class="form-check my-2">
						<input class="form-check-input" type="checkbox" v-model="signup.email_consent" id="emailConsent">
						<label class="form-check-label" for="emailConsent">
							I consent to receive emails from Rotary Trinidad and Tobago*
						</label>
					</div>
					<div class="form-check my-2">
						<input class="form-check-input" type="checkbox" v-model="signup.data_consent" value="" id="dataConsent">
						<label class="form-check-label" for="dataConsent">
							I agree to allow Rotary Trinidad and Tobago and Soca Islands to store and process my personal data*
						</label>
					</div>
					<div class="form-check my-2">
						<input class="form-check-input" type="checkbox" v-model="signup.agree_terms" id="agreeTerms">
						<label class="form-check-label" for="agreeTerms">
							I agree to Soca Islands terms and policies found at <a href="https://socaislands.com/legal-stuff" target="_legal">https://socaislands.com/legal-stuff</a>*
						</label>
					</div>
				</div>
				<div class="col-12" v-if="showFormError">
					<div class="alert alert-danger">
						<div class="h4">Account Creation Error</div>
						<div>{{ formError }}</div>
					</div>
				</div>
				<div class="col-12 my-3 d-grid ">
					<button class="btn btn-dark btn-lg" :disabled="!isFormValid || isBusy" @click="btnContinueClick">
						<span v-show="!isBusy">Continue</span>
						<div class="spinner-border text-light" role="status" v-show="isBusy">
							<span class="visually-hidden">Loading...</span>
						</div>
					</button>
				</div>
			</div>
		</div>
		<div class="col-12 col-lg-3">
		</div>
	</div>
</template>

<script>
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; 
import { db, collections } from "../../firebaseConfig";

export default {
	name: "SignupForm",
	components: {
		
	},
	computed:{
		isFormValid(){
			const { signup, contact, password, confirm_password } = this;
			if(!signup.fname) return false;
			if(!signup.lname) return false;
			if(!contact.email) return false;
			if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(contact.email)) return false;
			if(!contact.phone) return false;
			if(!signup.gender) return false;
			if(!signup.country) return false;
			if(!password) return false;
			if(password !== confirm_password) return false;
			if(!signup.data_consent) return false;
			if(!signup.email_consent) return false;
			if(!signup.agree_terms) return false;
			return true;
		}
	},
	data(){
		return{
			showPassword: false,
			isBusy: false,
			isPhoneValid: false,
			showFormError: false,
			formError: "",
			password: "",
			confirm_password: "",
			contact: {
				email: "",
				phone: "",
			},
			signup:{
				fname: "",
				lname: "",
				gender: "",
				country: "",
				data_consent: false,
				email_consent: false,
				agree_terms: false,
				role: "subscriber",
				created_at: Math.floor(Date.now()/1000),
				updated_at: Math.floor(Date.now()/1000),   
			},
		}
	},
	methods:{
		btnContinueClick(){
			const { signup, contact, password } = this;
			this.isBusy = true;
			this.showFormError = false;
			try{
				const auth = getAuth();
				createUserWithEmailAndPassword(auth, contact.email, password)
				.then(async (userCredential) => {
					const user = userCredential.user;
					updateProfile(auth.currentUser, {
						displayName: `${signup.fname} ${signup.lname}`, phoneNumber: `${signup.phone}`, photoURL: "https://example.com/jane-q-user/profile.jpg"
					}).then(() => {
						console.log("User profile updated");
					}).catch((error) => {
						console.log(error);
					});

					//create user profile
					const profileRef = doc(db, collections.PROFILES, user.uid);
					const contactRef = doc(db, collections.CONTACTS, user.uid);
					setDoc(profileRef, signup);
					setDoc(contactRef, contact);
					sendEmailVerification(auth.currentUser);

					this.$store.dispatch("fetchProfile", user)
					//this.$router.push("/profile")
					this.$emit('closeLoginModal');

				})
				.catch((error) => {
					this.formError = error.message.replace("Firebase:", "");
					this.showFormError = true;
					this.isBusy = false;
				});
			}catch(error){
				console.log(error);
				this.formError = "We encountered an error while creating your account. Please try again later oe email explore@socaislands.com for support.";
				this.showFormError = true;
				this.isBusy = false;
			}
		},
	},

	mounted(){
		this.$store.commit("setPageTitle", "sign-up");
	}

}
</script>
