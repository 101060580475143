<template>
	<div class="row p-3" v-show="shoppingCart.length > 0" id="pageTop">
		<div class="col-12" v-show="showPaymentForm === false">
			<div class="row mb-4" v-for="(lineItem, tktCategoryIdx) in shoppingCart.filter((item) => item.qty)" :key="tktCategoryIdx">
				<template v-for="lineItemQty in lineItem.qty" :key="lineItemQty">
					<div class="col-12 alert-secondary alert">
						<h5>{{lineItem.ticket}} No. {{lineItemQty}}</h5>
					</div>
					<div class="col-12 col-md-6 my-2">
						<label class="small text-secondary">First name* </label>
						<input class="form-control" v-model="order.attendees[tktCategoryIdx].fname[lineItemQty-1]" />
					</div>
					<div class="col-12 col-md-6 my-2">
						<label class="small text-secondary">Last name* </label>
						<input class="form-control" v-model="order.attendees[tktCategoryIdx].lname[lineItemQty-1]" />
					</div>
					<div class="col-12 col-md-6 my-2 mb-5">
						<label for="formFile" class="small text-secondary">Meal option*</label>
						<select class="form-select" v-model="order.attendees[tktCategoryIdx].meal[lineItemQty-1]">
							<option value="NON-VEGGIE">Non-vegetarian</option>
							<option value="VEGGIE">Vegetarian</option>
						</select>
					</div>
				</template>
			</div>

			<div class="row">
				<div class="col-12 alert alert-secondary">
					<h5>Ticket Buyer</h5>
				</div>
				<div class="col-12 col-md-6 my-2">
					<label class="small text-secondary">First name* </label>
					<input class="form-control" v-model="order.buyer.fname" />
				</div>
				<div class="col-12 col-md-6 my-2">
					<label class="small text-secondary">Last name* </label>
					<input class="form-control" v-model="order.buyer.lname" />
				</div>
				<div class="col-12 col-md-6 my-2">
					<label class="small text-secondary">Email address* </label>
					<input class="form-control" v-model="order.buyer.email" />
				</div>
				<div class="col-12 col-md-6 my-2">
					<label class="small text-secondary">Mobile number* </label>
					<input class="form-control" v-model="order.buyer.phone" />
				</div>
				<div class="col-12 col-md-6 my-2">
					<label for="formFile" class="small text-secondary">Rotary or Rotaract club*</label>
					<select class="form-select" v-model="order.buyer.rotary_club">
						<option>NOT APPLICABLE</option>
						<option disabled>------------</option>
						<option v-for="(club, index) in clubs.ttclubs" :key="index">{{club.club_name}}</option>
						<option disabled>------------</option>
						<option>MY CLUB IS NOT LISTED</option>
					</select>
				</div>
			</div>

			<div class="row mt-5">
				<div class="col-12 alert alert-secondary">
					<h5>Vaccination Card Upload</h5>
				</div>
				<div class="text-secondary my-2">
					Please submit individual copies of the vaccination cards for yourself and each member of your group (12 years old and over). You may use the button below to upload one or more copies of vaccination cards.
				</div>
				<div class="col-12 col-md-6 my-2">
					<label for="vaxxFile" class="small text-secondary">Vaccination card (JPG PNG PDF)*</label>
					<input class="form-control" type="file" accept=".jpg, .jpeg, .png, .pdf" id="vaxxFile" @change="uploadVaxxCard">

					<div class="progress" v-if="loadProgress > 0 && loadProgress < 100">
						<div class="progress-bar" :style="`width: ${loadProgress}%;`" role="progressbar" aria-valuenow="loadProgress" aria-valuemin="0" aria-valuemax="100">{{ Math.ceil(loadProgress) }}%</div>
					</div>
					<div v-if="loadProgress >= 100" align="right">
						<i class="bi bi-check-all text-success"></i>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-12 mt-5 d-grid">
					<button class="btn btn-dark btn-lg" @click="btnContinueClick">
						Continue
					</button>
				</div>
			</div>
		</div>

		<div class="col-12" v-show="showPaymentForm === true">
			<div class="row">
				<div class="col-12 mb-2 alert alert-secondary">
					<h4>Billing Information</h4>
					<p>As listed with your bank and credit card company. 
						Republic and Scotiabank customers, please contact your bank before completing the registration. 
						The transaction will appear on your credit card as <small class="fw-bold">SOCAISLAND*ROTARY</small>
					</p>
				</div>
				<div class="alert alert-success my-3 small">
					<i class="fas fa-lock"></i> This is a secure and SSL encrypted payment.
				</div>
				<div id="payment-element">
					<div class="d-flex justify-content-center">
						<div class="spinner-border" style="width: 5rem; height: 5rem;" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
					<!-- Mount the Payment Element here -->
				</div>
				<div class="alert alert-danger alert-dismissible my-3 small" id="payment-error-alert" v-if="showPaymentError">
					<i class="fas fa-bell"></i> There was a problem while charging your credit card. Please review your credit card details 
					and/or contact your financial institution before trying again.
					<p><strong>Response - {{ paymentErrorMessage }}</strong></p>
					<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
				</div>
				<div class="col-12 my-2">
					<div class="form-check">
						<input class="form-check-input" type="checkbox" value="" id="agreeRotaryTerms" v-model="order.agreeRotaryTerms">
						<label class="form-check-label" for="agreeRotaryTerms">
							I consent to receive emails from Rotary International District 7030.*
						</label>
					</div>
				</div>
				<div class="col-12 my-2">
					<div class="form-check">
						<input class="form-check-input" type="checkbox" value="" id="agreeRotaryData" v-model="order.agreeRotaryData">
						<label class="form-check-label" for="agreeRotaryData">
							I agree to allow Rotary International District 7030 to store and process my personal data.*
						</label>
					</div>
				</div>
				<div class="col-12 my-2">
					<div class="form-check">
						<input class="form-check-input" type="checkbox" value="" id="agreeSocaIslandsData" v-model="order.agreeSocaIslandsData">
						<label class="form-check-label" for="agreeSocaIslandsData">
							I agree to allow Soca Islands to store and process my personal data.*
						</label>
					</div>
				</div>
				<div class="col-12 my-4 d-grid">
					<label class="form-label small">By submitting this form you are agreeing to Soca Islands' <a href="https://socaislands.com/legal-stuff">Terms and Privacy Policy</a></label>
					<button class="btn btn-dark btn-lg mt-2" @click="chargeCreditCard" :disabled="isFormBusy">
						<div class="spinner-border text-light" role="status" v-if="isFormBusy">
							<span class="visually-hidden">Loading...</span>
						</div>
						<span v-else>Pay now</span>
					</button>

					<button class="btn btn-link my-5" @click="showPaymentForm = !showPaymentForm;">
						Go back
					</button>
				</div>
			</div>
		</div>
	</div>
	<div class="row" v-show="shoppingCart.length < 1">
		<div class="col-12">
			<div class="row">
				<div class="col-12 test-secondary" align="center">
					<em>Your cart is empty...</em>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { clubs } from '../data/TTClubs';
import { doc, setDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { db, collections } from '../firebaseConfig';
export default {
	name: 'cart',
	emits:['closeCartModal'],
	props:{
		shoppingCart: {
			type: Array,
			default: ()=>{ return []},
		},
		resetPaymentForm:{
			type: Boolean,
			default: false,
		},
		cartTotal:{
			type: Number,
			default: 0,
		},
		stripePaymentAmount:{
			type: Number,
			default: 0,
		}
	},
	computed:{
		stripeClientSecret(){
			return this.$store.state.stripeClientSecret;
		},
		isFormBusy(){
			return this.$store.state.isReservationFormBusy;
		},
		userProfile(){
			return this.$store.state.userProfile;
		},
	},
	data(){
		return {
			loadProgress: 0,
			showPaymentForm: false,
			clubs,
			attendee: {
				fname: [],
				lname: "",
				meal: "",
			},
			order: {
				id: "",
				date: "",
				status: "",
				attendees:[
					{
						fname: [],
						lname: [],
						meal: [],
						ticket_name: [],
						ticket_price: [],
					},
					{
						fname: [],
						lname: [],
						meal: [],
						ticket_name: [],
						ticket_price: [],
					},
					{
						fname: [],
						lname: [],
						meal: [],
						ticket_name: [],
						ticket_price: [],
					}
				],
				buyer: {
					fname: "",
					lname: "",
					email: "",
					phone: "",
					rotary_club:"",
					vaxx_card: "",
				},
				billing:{
					full_name: "",
					email: "",
					phone: "",
					amount: "",
					stripe_amount: "",
				},
				agreeRotaryData: false,
				agreeSocaIslandsData: false,
				agreeRotaryTerms: false,
			},
			elements: null,
			stripe: null,
			showPaymentError: false,
			paymentErrorMessage: "",
			description: "",
		}
	},
	methods:{
		async btnContinueClick(){
			this.showPaymentForm = !this.showPaymentForm;
			const pageTop = document.getElementById('pageTop');
			pageTop.scrollIntoView(true);

			//get stripe amount
			this.description = this.shoppingCart.map((item) => item.qty > 0 ? `${item.qty} - ${item.ticket}` : null).join(" | ");
			this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
			const options = {
				clientSecret: await this.getStripeClientSecret(),
			};
			const appearance = {
				theme: "stripe",
				variables: {
					colorText: "#32325d",
				},
			};
			this.elements = this.stripe.elements(options, appearance);
			const paymentElement = this.elements.create("payment");
			paymentElement.mount("#payment-element");
		},
		async btnPayClick(){
			this.order.id = Date.now();
			this.order.date = new Date();
			this.order.status = "complete";
			this.order.cart = this.shoppingCart;
			this.order.uid = this.userProfile.id;
			this.order.billing.amount = this.cartTotal;
			this.order.billing.stripe_amount = this.stripePaymentAmount;
			await setDoc(doc(db, collections.ORDERS, String(this.order.id)), this.order);

			this.$emit('closeCartModal');
		},
		async getStripeClientSecret(){
			this.$store.commit("setIsReservationFormBusy", true);
			if(!this.stripeClientSecret){
				const paymentIntent = await fetch("https://app.socaislands.com/api/stripe/create", {
					method: "POST",
					headers: {
						"Content-Type": "application/json"
					},
					body: JSON.stringify({
						stripeAmount: this.stripePaymentAmount,
						description: this.description,
						currency: 'ttd',
						statement_descriptor_suffix: 'ROTARY', 
						capture_method: 'automatic',
						receipt_email: this.order.buyer.email
					})
				})
				.then(response => response.json())
				.then(data => {
					return data;
				});
				
				this.$store.commit("setIsReservationFormBusy", false);
				this.$store.commit("setStripeClientSecret", paymentIntent.client_secret);
				return paymentIntent.client_secret
			}else{
				this.$store.commit("setIsReservationFormBusy", false);
				return this.stripeClientSecret;
			}

		},
		async chargeCreditCard(){
			this.$store.commit("setIsReservationFormBusy", true);
			const { paymentIntent, error } = await this.stripe.confirmPayment({
				elements: this.elements,
				confirmParams: {
					return_url: "https://apps.socaislands.com/bookings",
				},
				redirect: "if_required",
			});
			
			if(error){
				this.showPaymentError = true;
				this.paymentErrorMessage = error.message;
				this.$store.commit("setIsReservationFormBusy", false);
				return { error: error };
			}else if(paymentIntent){
				this.order.id = Date.now();
				this.order.date = new Date();
				this.order.status = "complete";
				this.order.cart = this.shoppingCart;
				this.order.uid = this.userProfile.id;
				this.btnPayClick();
			}
		},
		uploadVaxxCard(e){
			this.$store.commit("setIsReservationFormBusy", true);
			var file =   e.target.files[0]
			var extension = file.type
			var imgFileName = `${this.userProfile.fname} ${this.userProfile.lname} ${Math.floor(Date.now()/1000) }.${extension.replace(/(.*)\//g, '')}`

			const storage = getStorage();
			const storageRef = ref(storage, `vaxxcards/${imgFileName}`);
			const metadata = {
				cacheControl: "public,max-age=300",
				origin: "events"
			}
			const uploadTask = uploadBytesResumable(storageRef, file, metadata);

			uploadTask.on('state_changed', 
				(snapshot) => {
					this.loadProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
					console.log('Upload is ' + this.loadProgress + '% done');
					switch (snapshot.state) {
						case 'paused':
							console.log('Upload is paused');
							break;
						case 'running':
							console.log('Upload is running');
							break;
						case 'unauthorized':
							console.log("There was error uploding your file.")
							break
						case 'unknown':
							console.log("There was error uploding your file.")
							break
					}
				}, 
				(error) => {
					console.log(error);
				}, 
				() => {
					getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
						this.$store.commit("setIsReservationFormBusy", false);
						this.order.buyer.imgFile = downloadURL; 
						this.order.buyer.imgFileName = imgFileName;
					});
				}
			);
		},
	},
	mounted(){
	}
}
</script>